<template>
  <div class="index">
    <!-- <div class="box1">
			<div class="box1_top">
				<div class="box1_title1">孩子王的快乐你不懂</div>
				<div class="box1_int1">The GO GO BEAR will bring infinite happiness to children</div>
				<img src="../assets/btn1.png" />
			</div>
			<div class="box1_bot">
				<Carousel autoplay v-model="value" loop>
				  <CarouselItem>
				    <div class="demo-carousel">
							<img src="../assets/top_logo.png" />
						</div>
				  </CarouselItem>
				  <CarouselItem>
				    <div class="demo-carousel">
				  		<img src="../assets/top_logo.png" />
				  	</div>
				  </CarouselItem>
				</Carousel>
			</div>
		</div> -->
		<div class="box1">
			<img src="../assets/banner.jpg" class="bg" />
			<div class="box1_content">
				<div class="box1_title1">孩子王的快乐你不懂</div>
				<div class="box1_int1">The GO GO BEAR will bring infinite happiness to children</div>
				<img @click="goMore" src="../assets/btn1.png" />
			</div>
		</div>
		<div class="box2">
			<img src="../assets/bg1.jpg" class="bg"/>
			<div class="box2_content">
				<div class="box2_title_big">
					品牌介绍
				</div>
				<div class="box2_title_big_en">
					BRAND INTRODUCTION
				</div>
				<div class="border"></div>
				<div class="font_intro">
					跑跑太空熊是上海马尼亚娱乐咨询管理有限公司2020年推出儿童共享童车品牌。 延承多年从事儿童游乐，玩具行业始终坚持对产品严谨认真的态度。加入企业文化传承，结合多年来运用管理体系。 致力于打造当下优质的物联网儿童游艺产品。
				</div>
				<div class="appeal">
					<div class="appeal_title">品牌创想</div>
					<div class="appeal_title_en">BRAND APPEAL</div>
					<p>1.跑跑太空熊 是多元化集团借由一个品牌搭建与投资者、消费者之间的桥梁。让全新的无人共享童车进入各个幸福美满家庭的生活中。 身为行业的领导者，我们清楚舞台对品牌的重要性。</p>
					<p>2.我们相信快乐的体验，安全卫生的产品要从行业的领导者发起，所以创造三赢的循环就是集团的目标。让新兴的品牌成长，消费者更有意愿体验的产品，帮助更加有实力的投资者扩张市场，彼此串联来相互提升。</p>
					<div class="bg_shadow">
						<img src="../assets/bear.png" class="bg_shadowImg"/>
					</div>
				</div>
				<div class="innovation">
					<div class="appeal_title">品牌理念</div>
					<div class="appeal_title_en">BRAND INNOVATION</div>
					<p style="margin-top: 10px;">1.不需要专程去大型儿童乐园去租赁童车；</p>
					<p>2.不需要特地从家中劳累地搬上搬下童车；</p>
					<p>3.不需要花费高额的费用来购买大型童车。</p>
					<p>4.扫码-借车-还车，一步到位。</p>
					<p>5.因为跑跑太空熊·共享童车能让，幸福美满的家庭与顶级童车触手可及。</p>
					<div class="bg_shadow">
						<img src="../assets/bear.png" class="bg_shadowImg" style="width: 60%;"/>
					</div>
				</div>
				<div class="image" id="#more">
					<div class="appeal_title">产品形象</div>
					<div class="appeal_title_en">PRODUCT IMAGE</div>
					<div class="image_content">
						<div class="image_left">
							<p style="margin-top: 10px;">1.产品形象设计主调采用日系动漫元素。</p>
							<p>2.引用进口不锈钢拥有极强的抗老化条件，并且结合当下潮流的亮眼明色彩搭配设计，打造全新的视觉概念，用以表达方便、 快速、以产品传递全新的共享童车的理念。</p>
						</div>
						<div class="image_right">
							<img src="../assets/bear.png" />
						</div>
					</div>
					<div class="bg_shadow">
						<img src="../assets/bear.png" class="bg_shadowImg" style="width: 70%;"/>
					</div>
				</div>
				
				<div class="presentation">
					<img src="../assets/icon_title.png" class="pre_icon" />
					<div class="pre_title">品牌热度</div>
					<div class="pre_title_en">CASE PRESENTATION</div>
					<div class="pre_sys">无人租赁·溜娃高手·分享快乐</div>
					<div style="border-radius: 0.15rem; margin-top: 0.1rem;">
						<Swiper>
						  <Slide style="border-radius: 0.15rem;">
								<img src="../assets/hot1.png" class="hot_pic"/>
						  </Slide>
							<Slide style="border-radius: 0.15rem;">
								<img src="../assets/hot2.png" class="hot_pic"/>
							</Slide>
							<Slide style="border-radius: 0.15rem;">
								<img src="../assets/hot3.png" class="hot_pic"/>
							</Slide>
							<Slide style="border-radius: 0.15rem;">
								<img src="../assets/hot4.png" class="hot_pic"/>
							</Slide>
						</Swiper>
						<!-- <Carousel autoplay v-model="value" loop>
						  <CarouselItem style="width: auto;">
						    <div class="demo-carousel" style="border-radius: 0.15rem;">
									<img src="../assets/hot1.png" class="hot_pic"/>
								</div>
						  </CarouselItem>
						  <CarouselItem>
						    <div class="demo-carousel">
						  		<img src="../assets/hot2.png" class="hot_pic"/>
						  	</div>
						  </CarouselItem>
							<CarouselItem>
							  <div class="demo-carousel">
									<img src="../assets/hot3.png" class="hot_pic"/>
								</div>
							</CarouselItem>
							<CarouselItem>
							  <div class="demo-carousel">
									<img src="../assets/hot4.png" class="hot_pic"/>
								</div>
							</CarouselItem>
						</Carousel> -->
					</div>
				</div>
				<div class="advantage">
					<img src="../assets/icon_title.png" class="pre_icon" />
					<div class="pre_title">品牌优势</div>
					<div class="pre_title_en">BRAND ADVANTAGE</div>
					<div class="pre_sys">我们有六大优势</div>
					<div class="flex_row">
						<div class="flex_cloumn">
							<img src="../assets/at_icon1.png" class="at_icon"/>
							<!-- <div>优质客服</div> -->
						</div>
						<div class="flex_cloumn">
							<img src="../assets/at_icon2.png" class="at_icon"/>
							<!-- <div>大数据支持</div> -->
						</div>
						<div class="flex_cloumn">
							<img src="../assets/at_icon3.png" class="at_icon"/>
							<!-- <div>风险防控</div> -->
						</div>
					</div>
					<div class="flex_row">
						<div class="flex_cloumn">
							<img src="../assets/at_icon4.png" class="at_icon"/>
							<!-- <div>合作多样化</div> -->
						</div>
						<div class="flex_cloumn">
							<img src="../assets/at_icon5.png" class="at_icon"/>
							<!-- <div>盈利多样化</div> -->
						</div>
						<div class="flex_cloumn">
							<img src="../assets/at_icon6.png" class="at_icon"/>
							<!-- <div>移动监控端</div> -->
						</div>
					</div>
				</div>
				<div class="honor">
					<img src="../assets/icon_title.png" class="pre_icon" />
					<div class="pre_title">企业荣誉</div>
					<div class="pre_title_en">ENTERPRISE HONOR</div>
					<div class="pre_sys tal">顶级联盟-跑跑太空熊·智能共享童车所使用的童车均为迈凯伦、奔驰、玛莎拉蒂、兰博基尼、帕加尼等众多顶级正版授权车辆，保证产品正版授权的同时又提供了安全稳定性。真正以奢华享受，亲民价格取胜。</div>
					<div class="el_honor">
						<el-carousel :interval="4000" type="card" height="0.84rem">
						    <el-carousel-item >
						      <img src="../assets/honor1.png" class="honor_pic"/>
						    </el-carousel-item>
								<el-carousel-item >
								  <img src="../assets/honor2.png" class="honor_pic"/>
								</el-carousel-item>
								<el-carousel-item >
								  <img src="../assets/honor3.png" class="honor_pic"/>
								</el-carousel-item>
								<el-carousel-item >
								  <img src="../assets/honor4.png" class="honor_pic"/>
								</el-carousel-item>
								<el-carousel-item >
								  <img src="../assets/honor5.png" class="honor_pic"/>
								</el-carousel-item>
						  </el-carousel>
					</div>
				</div>
				<div class="introduction">
					<img src="../assets/icon_title.png" class="pre_icon" />
					<div class="pre_title">团队介绍</div>
					<div class="pre_title_en">TEAM INTRODUCTION</div>
					<div class="pre_sys tal">我们有专业的线上推广运营团队。20多位世界500强背景程序员以及来自巨头企业、985、211、双一流高校的专业人才，帮助你做市场分析，制作最优推广方案。由品牌洽谈，童车定位到最后的设计，制造，再到改装，升级，全部一条龙服务。覆盖产业链上中下游，为城市合伙人的加入提供强有力的保障。</div>
					<div class="team_bg">
						<img src="../assets/intro_bg.png" class="team_bg_pic"/>
						<div class="team_pic">
							<img src="../assets/team.jpg" class="team_pic_img">
						</div>
					</div>
				</div>
				<div class="popularity">
					<img src="../assets/icon_title.png" class="pre_icon" />
					<div class="pre_title">客户体验</div>
					<div class="pre_title_en">BRAND POPULARITY</div>
					<div class="pre_sys">
						无人租赁·溜娃高手·分享快乐
					</div>
					<div class="pop_bg">
						<img src="../assets/pop_bg.png" class="pop_bg_pic"/>
						<div class="pop_pic">
							<!-- <img src="../assets/pop1.png" class="pop_pic_img"/> -->
							<Swiper>
							  <Slide>
									<img src="../assets/pop1.png" class="pop_pic_img"/>
							  </Slide>
								<Slide>
									<img src="../assets/pop2.jpg" class="pop_pic_img"/>
								</Slide>
								<Slide>
									<img src="../assets/pop3.jpg" class="pop_pic_img"/>
								</Slide>
								<Slide>
									<img src="../assets/pop4.jpg" class="pop_pic_img"/>
								</Slide>
							</Swiper>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import { Swiper, Slide } from 'vue-swiper-component';

export default {
  name: 'Index',
  components: {
		Swiper,
    Slide
  },
	data(){
		return {
			value : 1,
			
		}
	},
	methods:{
		goMore(){
			document.getElementById('#more').scrollIntoView({
				behavior: "smooth",  // 平滑过渡
				block:    "start"  // 上边框与视窗顶部平齐。默认值
			});
		}
	},
	mounted(){

	}
}
</script>

<style scoped="scoped">
	
	.box1{
		width: 100%;
		
		position: relative;
	}
	.box1_top{
		box-sizing: border-box;
		padding: 80px 70px 25px;
	}
	.box1_title1{
		font-size: 24px;
		color: #333;
		font-weight: bold;
	}
	.box1_int1{
		box-sizing: border-box;
		padding: 20px 0;
	}
	.box1_bot{
		width: 100%;
		height: 200px;
	}
	.box2{
		width: 100%;
		height: auto;
		position: relative;
		transform: translateY(-0.05rem);
	}
	.bg{
		width: 100%;
		height: 100%;
	}
	.box2_content{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		box-sizing: border-box;
		padding: 0.3rem;
	}
	.box1_content{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		box-sizing: border-box;
		padding: 0.5rem 0.3rem 0.3rem;
	}
	.box2_title_big{
		font-size: 26px;
		color: #fffa5a;
		font-weight: bold;
	}
	.box2_title_big_en{
		font-size: 15px;
		color: #d4e6ff;
	}
	.font_intro{
		font-size: 0.13rem;
		color: #fff;
	}
	.border{
		width: 25px;
		border-top: 1px solid #d4e6ff;
		margin: 0.05rem auto;
	}
	.appeal{
		margin-top: 0.25rem;
		/* height: 3.5rem; */
		text-align: left;
		box-sizing: border-box;
		padding: 0.32rem 0.4rem;
		overflow: hidden;
		background-color: #fff;
		border-radius: 0.8rem;
		width: 96%;
		position: relative;
		z-index: 999;
		overflow-y: auto;
	}
	.appeal::-webkit-scrollbar{width:0;}
	@media screen and (min-width: 375px){
		.appeal{
			margin-top: 0.28rem;
			height: 3.5rem;
			text-align: left;
			box-sizing: border-box;
			padding: 0.32rem 0.4rem;
			overflow-y: auto;
		}
	}
	.appeal_title{
		font-size: 0.17rem;
		color: #000000;
	}
	.appeal_title_en{
		font-size: 0.13rem;
		color: #979797;
	}
	.appeal_title_en::after{
		content: '';
		display: block;
		width: 20px;
		height: 1px;
		margin-top: 10px;
		background-color: #979797;
	}
	.appeal p{
		font-size: 0.13rem;
		color: #000;
		margin-top: 0.1rem;
	}
	.innovation{
		margin-top: 0.25rem;
		/* height: 2.5rem; */
		text-align: left;
		box-sizing: border-box;
		padding: 0.35rem;
		padding-right: 0.2rem;
		overflow: hidden;
		background-color: #fff;
		border-radius: 0.8rem;
		width: 96%;
		position: relative;
	}
	.innovation p{
		font-size: 0.13rem;
		color: #000;
		
	}
	.image{
		margin-top: 0.25rem;
		/* height: 3.1rem; */
		text-align: left;
		box-sizing: border-box;
		padding: 0.35rem 0.4rem;
		overflow: hidden;
		background-color: #fff;
		border-radius: 0.8rem;
		width: 96%;
		position: relative;
	}
	.image_content{
		display: flex;
	}
	.image_left{
		width: 60%;
	}
	.image_right{
		width: 40%;
	}
	.image_right img{
		width: 130%;
	}
	.image_left p{
		font-size: 0.13rem;
		color: #000;
	}
	.presentation{
		margin-top: 0.5rem;
		/* height: 3.75rem; */
		text-align: center;
		box-sizing: border-box;
		padding: 0.25rem 0.25rem;
		overflow: hidden;
		position: relative;
		background-color: #fff;
		border-radius: 0.3rem;
		width: 96%;
		position: relative;
	}
	@media screen and (min-width: 375px){
		.presentation{
			margin-top: 0.8rem;
		}
	}
	.pre_title{
		font-size: 0.26rem;
		color: #5454b0;
		font-weight: bold;
		
	}
	.pre_title_en{
		font-size: 0.15rem;
		color: #999;
	}
	.pre_title_en::after{
		content: '';
		display: block;
		width: 20px;
		height: 1px;
		margin: 0 auto;
		margin-top: 10px;
		background-color: #333;
	}
	.pre_sys{
		font-size: 0.15rem;
		color: #000;
		margin-top: 0.1rem;
	}
	.pre_icon{
		position: absolute;
		left: 0.45rem;
		top: 0.35rem;
		width: 0.45rem;
	}
	.advantage{
		margin-top: 0.25rem;
		/* height: 3.2rem; */
		text-align: center;
		box-sizing: border-box;
		padding: 0.25rem 0.25rem;
		overflow: hidden;
		position: relative;
		background-color: #fff;
		border-radius: 0.3rem;
		width: 96%;
		position: relative;
	}
	.flex_row{
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		margin-top: 0.1rem;
	}
	.flex_cloumn{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.flex_cloumn div{
		color: #595bb1;
		font-size: 0.13rem;
	}
	.at_icon{
		width: 0.65rem;
	}
	.hot_pic{
		width: 100%;
		border-radius: 0.15rem;
	}
	.honor{
		margin-top: 0.25rem;
		/* height: 3.15rem; */
		text-align: center;
		box-sizing: border-box;
		padding: 0.15rem 0.25rem;
		overflow: hidden;
		position: relative;
		background-color: #fff;
		border-radius: 0.3rem;
		width: 96%;
		position: relative;
	}
	.tal{
		text-align: left;
	}
	.honor_pic{
		height: 100%;
	}
	.introduction{
		margin-top: 0.25rem;
		/* height: 4.65rem; */
		text-align: center;
		box-sizing: border-box;
		padding: 0.15rem 0.25rem;
		overflow: hidden;
		position: relative;
		background-color: #fff;
		border-radius: 0.3rem;
		width: 96%;
		position: relative;
	}
	.team_bg{
		height: 1.56rem;
		margin-top: 0.05rem;
		position: relative;
	}
	.team_bg_pic{
		width: 105%;
		height: 105%;
		transform: translate(-2.5%,-2.5%);
	}
	.team_pic{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.team_pic_img{
		width: 100%;
		height: 100%;
		border-radius: 0.2rem;
	}
	.popularity{
		margin-top: 0.25rem;
		/* height: 7rem; */
		text-align: center;
		box-sizing: border-box;
		padding: 0.15rem 0.25rem;
		overflow: hidden;
		position: relative;
		background-color: #fff;
		border-radius: 0.3rem;
		width: 96%;
		position: relative;
	}
	.pop_bg{
		height: 5.15rem;
		margin-top: 0.2rem;
		position: relative;
	}
	.pop_bg_pic{
		width: 105%;
		transform: translateX(-2.5%);
	}
	.pop_pic{
		position: absolute;
		top: 0.06rem;
		left: 0;
		width: 100%;
		height: 5rem;
	}
	.pop_pic_img{
		width: 100%;
	}
	.bg_shadow{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.bg_shadowImg{
		width: 80%;
		opacity: 0.1;
	}
</style>