import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/headquarters',
    name: 'headquarters',
    component: () => import(/* webpackChunkName: "about" */ '../views/headquarters.vue')
  },
	{
	  path: '/cooperation',
	  name: 'cooperation',
	  component: () => import(/* webpackChunkName: "about" */ '../views/cooperation.vue')
	},
	{
	  path: '/surrounding',
	  name: 'surrounding',
	  component: () => import(/* webpackChunkName: "about" */ '../views/surrounding.vue')
	},
	{
	  path: '/teaching',
	  name: 'teaching',
	  component: () => import(/* webpackChunkName: "about" */ '../views/teaching.vue')
	},
	{
	  path: '/news',
	  name: 'news',
	  component: () => import(/* webpackChunkName: "about" */ '../views/news.vue')
	},{
	  path: '/Contact',
	  name: 'Contact',
	  component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
	},{
	  path: '/art1',
	  name: 'art1',
	  component: () => import(/* webpackChunkName: "about" */ '../views/article/art1.vue')
	},{
	  path: '/art2',
	  name: 'art2',
	  component: () => import(/* webpackChunkName: "about" */ '../views/article/art2.vue')
	},{
	  path: '/art3',
	  name: 'art3',
	  component: () => import(/* webpackChunkName: "about" */ '../views/article/art3.vue')
	},{
	  path: '/art4',
	  name: 'art4',
	  component: () => import(/* webpackChunkName: "about" */ '../views/article/art4.vue')
	},{
	  path: '/art5',
	  name: 'art5',
	  component: () => import(/* webpackChunkName: "about" */ '../views/article/art5.vue')
	},{
	  path: '/Privacy',
	  name: 'Privacy',
	  component: () => import(/* webpackChunkName: "about" */ '../views/Privacy.vue')
	},{
	  path: '/terms',
	  name: 'terms',
	  component: () => import(/* webpackChunkName: "about" */ '../views/terms.vue')
	},{
	  path: '/art6',
	  name: 'art6',
	  component: () => import(/* webpackChunkName: "about" */ '../views/article/art6.vue')
	},{
	  path: '/art7',
	  name: 'art7',
	  component: () => import(/* webpackChunkName: "about" */ '../views/article/art7.vue')
	},{
	  path: '/art8',
	  name: 'art8',
	  component: () => import(/* webpackChunkName: "about" */ '../views/article/art8.vue')
	},{
	  path: '/art9',
	  name: 'art9',
	  component: () => import(/* webpackChunkName: "about" */ '../views/article/art9.vue')
	}
	
]
const originalPush = VueRouter.prototype.push
const scrollBehavior = function(to,from,savedPosition) {
	if(savedPosition){
		return savedPosition
	}else{
		return {x:0,y:0}
	}
}
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  routes,
	scrollBehavior
})

export default router
