<template>
  <div id="app">
		<top></top>
    <router-view/>
		<foot></foot>
  </div>
</template>

<script>
	import top from './components/top.vue';
	import foot from './components/foot.vue';
	export default {
	  components: {
	    top,
			foot
	  }
	}
</script>

<style>
#app {
  font-family: '微软雅黑';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
	margin: 0;
	
}
body{
	margin: 0;
	background-color: #d9d9d9 !important;
}
/* #app{
	position:relative;
}
#app::before{
	content:'';
	width:100%;
	height:100%;
	position:absolute;
	z-index:1000;
	background-color:#fff;
	opacity:0;
	top:0;
	left:0;
} */

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
