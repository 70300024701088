<template>
	<div class="top">
		<div class="nav1">
			<div v-if="hide"><img src="../assets/icon1.png" class="icon" @click="show"></div>
			<!-- <div v-if="!hide"><img src="../assets/icon1.png" class="icon" @click="show"></div> -->
			<div class="logo" v-if="hide">
				<div class="logo_shadow"></div>
				<img src="../assets/top_logo.png" class="logo_img ml24">
			</div>
			<div class="logo" v-if="!hide">
				<div class="logo_shadow"></div>
				<img src="../assets/top_logo.png" class="logo_img ml24">
			</div>
			<div class="flex_row" v-if="hide">
				<!-- <img src="../assets/icon3.png" class="icon"> -->
				<img src="../assets/icon2.png" @click="showPhone" class="icon ml5">
			</div>
			<div class="close" v-if="showMenu">
				<img src="../assets/icon_close.png" class="icon" @click="close">
			</div>
			<div class="close" v-if="showPhoneR">
				<img src="../assets/icon_close.png" class="icon" @click="close2">
			</div>
		</div>
		<div class="menu" v-if="showMenu">
			<!-- <div class="menu_row1 bt">
				<img src="../assets/icon_center.png" class="icon2"/>
				<img src="../assets/icon_order.png" class="icon2"/>
				<img src="../assets/icon_ques.png" class="icon2"/>
			</div> -->
			<div class="menu_list">
				<div class="bt" @click="goIndex">跑跑太空熊</div>
				<div class="bt" @click="goHead">品牌总部</div>
				<div class="bt" @click="gosur">品牌周边</div>
				<div class="bt" @click="goNews">相关资讯</div>
				<div class="bt" @click="goOpp">关于合作</div>
				<div class="bt" @click="goContrat">联系我们</div>
				<div class="bt bb" @click="goTeach">教学视频</div>
			</div>
			<!-- <div class="menu_login">
				<div @click="goRecord(1)">入场登记</div>
				<div>|</div>
				<div @click="goRecord(2)">安装登记</div>
			</div> -->
		</div>
		<div class="menu" v-if="showPhoneR">
			<div class="p_row bb ">招商热线：<span @click="callPhone">400-829-6068 转 2</span></div>
			
		</div>
	</div>
</template>

<script>
	export default {
	  name: 'top',
	  props: {
	    
	  },
		data() {
			return {
				hide: true,
				hide2: true,
				showMenu: false,
				showPhoneR: false
			}					
		},
		methods:{
			show(){
				this.hide = false
				this.showMenu = true
			},
			close(){
				this.hide = true
				this.showMenu = false
			},
			goIndex(){
				this.hide = true
				this.showMenu = false
				this.$router.push('/')
			},
			goHead(){
				this.hide = true
				this.showMenu = false
				this.$router.push('/headquarters')
			},
			goOpp(){
				this.hide = true
				this.showMenu = false
				this.$router.push('/cooperation')
			},
			gosur(){
				this.hide = true
				this.showMenu = false
				this.$router.push('/surrounding')
			},
			goTeach(){
				this.hide = true
				this.showMenu = false
				this.$router.push('/teaching')
			},
			goNews(){
				this.hide = true
				this.showMenu = false
				this.$router.push('/news')
			},
			goContrat(){
				this.hide = true
				this.showMenu = false
				this.$router.push('/Contact')
			},
			goRecord(i){
				if(i == 1){
					window.location.href = 'https://mny.gogo-bear.com/admin_look/entrance';
					window.open();
				}else if(i == 2){
					window.location.href = 'https://mny.gogo-bear.com/admin_look/install';
					window.open();
				}
			},
			showPhone(){
				this.hide2 = false
				this.hide = false
				this.showPhoneR = true
			},
			close2(){
				this.hide2 = true
				this.hide = true
				this.showPhoneR = false
			},
			callPhone(){
				window.location.href = 'tel://4008296068'
			}
		}
	}
</script>

<style scoped="scoped">
	.top{
		background-color: #fff;
	}
	.nav1{
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-sizing: border-box;
		padding: 0.1rem;
		height: 0.7rem;
	}
	.flex_row{
		display: flex;
	}
	.icon{
		width: 0.24rem;
	}
	.logo{
		flex: 1;
		position: relative;
	}
	.logo_shadow{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		opacity: 0;
	}
	.ml5{
		margin-left: 0.1rem;
	}
	.logo_img{
		width: 1.35rem;
	}
	.ml24{
		margin-left: 0.24rem;
	}
	.menu_row1{
		display: flex;
		justify-content: space-around;
		align-items: center;
		box-sizing: border-box;
		padding: 0.2rem 15%;
	}
	.icon2{
		width: 0.55rem;
	}
	.bt{
		border-top: 0.02rem solid #EAEAEA;
	}
	.bb{
		border-bottom: 0.02rem solid #EAEAEA;
	}
	.menu_list{
		
	}
	.menu_list div{
		text-align: left;
		box-sizing: border-box;
		padding: 0.2rem ;
		font-weight: bold;
	}
	.menu_login{
		height: 0.7rem;
		background-color: #F8F8F8;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}
	.menu_login div{
		color: #9C9C9C;
		font-size: 0.15rem;
	}
	.p_row{
		text-align: left;
		box-sizing: border-box;
		padding: 0.1rem;
		font-weight: bold;
	}
</style>
