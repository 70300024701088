import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import { Area, Popup, Dialog } from 'vant'
import 'vant/lib/index.css';
import axios from "@/util/axios.js"
Vue.use(Area)
Vue.use(Popup)
Vue.use(Dialog)
Vue.use(VideoPlayer)
Vue.use(ViewUI);
Vue.use(ElementUI);


Vue.config.productionTip = false
Vue.prototype.$axios = axios;


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
