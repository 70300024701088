<template>
	<div class="footer">
		<div class="foot_row1">
			<!-- <div @click="goPrivact">隐私政策</div> -->
			<router-link class="link" to="/Privacy">隐私政策</router-link>
			<div>|</div>
			<!-- <div @click="goterms">服务条款</div> -->
			<router-link class="link" to="/terms">服务条款</router-link>
			<div>|</div>
			<div >其他问题</div>
			<div>|</div>
			<!-- <div @click="goCon">加入我们</div> -->
			<router-link class="link" to="/Contact">加入我们</router-link>
		</div>
		<div class="foot_row2">
			<div>
				张经理：13538252032
			</div>
			<div>
				汪经理：15300710021
			</div>
		</div>
		<div class="foot_row3">
			<div>上海总部：上海市华江路850弄75号</div>
			<div>深圳研发部：深圳市海天一路</div>
			<div>深圳运营中心：深圳市南山区卓越前海壹号</div>
		</div>
		<div class="foot_row4">
			<img src="../assets/foot_icon1.png">
			<img src="../assets/foot_icon2.png">
			<img src="../assets/foot_icon3.png">
		</div>
		<div class="foot_row5">
			<div @click="goICP">沪ICP备2020031750号</div>
			<div @click="goNet">沪公网安备3101142008950号</div>
			<div>Copyright &copy; 2020上海马尼亚娱乐咨询管理有限公司</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'foot',
		methods:{
			goICP(){
				window.location.href = 'https://beian.miit.gov.cn';
				window.open(); 
			},
			goNet(){
				window.location.href = 'http://www.beian.gov.cn/portal/index.do';
				window.open(); 
			},
			goCon(){
				this.$router.push('/Contact')
			}, 
			goPrivact(){
				console.log('111')
				this.$router.push('/Privacy')
			},
			goterms(){
				console.log('111222')
				this.$router.push('/terms')
			}
		}
	}
</script>

<style scoped="scoped">
	.footer{
		width: 100%;
		height: 350px;
		background-color: #D9D9D9;
		box-sizing: border-box;
		padding: 30px 25px;
		font-size: 13px;
		color: #666;
	}
	.foot_row1{
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}
	.foot_row1 div{
		font-size: 14px;
		color: #333333;
		font-weight: bold;
	}
	.link{
		font-size: 14px;
		color: #333333;
		font-weight: bold;
	}
	.foot_row2{
		margin: 20px 0;
	}
	.foot_row4{
		display: flex;
		justify-content: space-around;
		align-items: center;
		box-sizing: border-box;
		padding: 20px 0 15px 0;
		border-bottom: 1px solid #666;
	}
	.foot_row5{
		margin-top: 20px;
	}
	.foot_row5 div{
		font-size: 13px;
		color: #666;
	}
</style>
